    <template>
        <div class="relative h-screen w-screen overflow-y-scroll lg:overflow-hidden">
            <div ref="contactUsModal" class="w-full h-full z-50 absolute top-0 left-0 bg-transparent backdrop-blur-2xl justify-center items-center drop-shadow-2xl duration-150 opacity-0 transition-opacity hide">
                <div class="bg-x-white rounded-2xl lg:w-1/2 w-full p-8 m-8">
                    <div class="flex justify-between w-full">
                        <p class="text-2xl lg:text-4xl">Contact Us</p>
                        <div class="cursor-pointer p-2 h-8 w-8 rounded-full bg-x-amber text-white flex justify-center items-center hover:opacity-80" @click="showContactUsModal()">
                            <font-awesome-icon icon="close" />
                        </div>
                    </div>
                    <div class="bg-green-500_">
                        <form @submit="handleSubmit" method="POST" class="w-full mx-auto mt-5 space-y-5">
                            <div class="">
                                <input type="text" placeholder="Your Name*" name="customer-name" class="px-3 py-3 placeholder-gray-600 text-black relative bg-white rounded-xl text-sm border-gray-200 border-2 shadow-none outline-none focus:outline-none focus:ring w-full" required/>
                            </div>

                            <div class="">
                                <input type="email" placeholder="Email*" name="customer-email" class="px-3 py-3 placeholder-gray-600 text-black relative bg-white rounded-xl text-sm border-gray-200 border-2 shadow-none outline-none focus:outline-none focus:ring w-full" required/>
                            </div>

                            <div class="">
                                <input type="text" placeholder="Company Name*" name="customer-company" class="px-3 py-3 placeholder-gray-600 text-black relative bg-white rounded-xl text-sm border-gray-200 border-2 shadow-none outline-none focus:outline-none focus:ring w-full" required/>
                            </div>

                            <div class="">
                                <textarea placeholder="Your Message*" name="customer-message" class="h-36 border-gray-200 border-2 px-3 py-3 placeholder-gray-600 text-black relative bg-white rounded-xl text-sm shadow-none outline-none focus:outline-none focus:ring w-full" required></textarea>
                            </div>

                            <div class="flex justify-center">
                                <button class="bg-x-amber text-white active:bg-x-yellow font-bold uppercase text-sm px-6 py-3 rounded-full shadow-none hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 " type="submit">
                                    Send message
                                </button>
                            </div>
                        </form>

                        <div ref="msg" class="absolute right-0 top-0 hidden">
                            <div class="bg-white rounded-2xl p-4 m-4">
                                <p class="">
                                    <font-awesome-icon icon="check-circle" class="text-green-500" />
                                    Message Sent Successfully
                                </p>
                                <p class="text-sm">
                                    We will get back to you as soon as possible
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div ref="termsModal" class="w-full h-full overflow-y-scroll z-50 absolute top-0 left-0 bg-transparent backdrop-blur-2xl justify-center drop-shadow-2xl duration-150 opacity-0 transition-opacity hide">
                <div class="bg-x-white text-black rounded-2xl lg:w-1/2 p-8 lg:my-10 m-8 overflow-hidden">
                    <div class="flex justify-between w-full mb-5">
                        <p class="text-2xl lg:text-4xl">Terms and Conditions</p>
                        <div class="cursor-pointer p-2 h-8 w-8 rounded-full bg-x-amber text-white flex justify-center items-center hover:opacity-80" @click="showTermsModal()">
                            <font-awesome-icon icon="close" />
                        </div>
                    </div>
                    <div class="h-full overflow-y-auto show-scrollbar">
                        <TermsPopup class="mb-20 pr-6"/>
                    </div>
                </div>
            </div>

            <div ref="privacyModal" class="w-full h-full overflow-y-scroll z-50 absolute top-0 left-0 bg-transparent backdrop-blur-2xl justify-center drop-shadow-2xl duration-150 opacity-0 transition-opacity hide">
                <div class="bg-x-white text-black rounded-2xl lg:w-1/2 p-8 lg:my-10 m-8 overflow-hidden">
                    <div class="flex justify-between w-full mb-5">
                        <p class="text-2xl lg:text-4xl">Privacy Policy</p>
                        <div class="cursor-pointer p-2 h-8 w-8 rounded-full bg-x-amber text-white flex justify-center items-center hover:opacity-80" @click="showPrivacyModal()">
                            <font-awesome-icon icon="close"/>
                        </div>
                    </div>
                    <div class="h-full overflow-y-auto show-scrollbar">
                        <PrivacyPopup class="mb-20 pr-6"/>
                    </div>
                </div>
            </div>

            <div class="_hidden _lg:block fixed bottom-0 right-0 lg:mb-16 lg:mr-8 mb-10 mr-2 z-40 h-auto w-auto hover:opacity-90 duration-300 drop-shadow-2xl bg-x-blue rounded-full border-2 border-x-blue">
                <!-- button whatsapp image -->
                <div class="grid gap-px hover:*:bg-x-amber *:duration-300 *:cursor-pointer">
                    <div class="rounded-full w-8 h-8 flex items-center justify-center _bg-green-500 p-6">
                        <p @click="showContactUsModal()" class="flex items-center justify-center duration-300">
                            <font-awesome-icon icon="envelope" class="text-2xl text-white" />
                        </p>
                    </div>
        
                    <div class="rounded-full h-8 w-8 flex items-center justify-center _bg-red-500 p-6">
                        <div class="">
                            <a href="https://wa.me/message/4JGX4OQ76HR4A1" class="h-6 w-6 flex justify-center duration-300">
                                <img :src="images.whatsapp" alt="Whatsapp" class="drop-shadow-2xl" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="fixed bottom-0 left-0 w-full z-40 mb-8 p-2 text-x-amber flex justify-center">
                <font-awesome-icon icon="arrow-down" class="animate-bounce"/>
            </div> -->

            <div class="fixed z-40 top-0 left-0 w-full border-x-white backdrop-blur-lg text-x-white flex justify-center items-center transition-all duration-300 h-20 2xl:px-56 lg:px-48 px-8">
                <div class="flex _md:justify-between justify-center w-full items-center">
                    <div>
                        <a href="https://optimalx.co.za" class="hover:opacity-80">
                            <img class="w-48" :src="images.logo" alt="logo">
                        </a>
                    </div>
                    <div class="hidden md:flex flex-wrap sm:gap-8 py-4 text-lg items-center uppercase font-bold w-full justify-end">
                        <div ref="secondary_button" class="">
                            <a class="hover:opacity-75 duration-30 cursor-pointer" href="https://guide.optimalx.co.za/">
                                Home
                            </a>
                        </div>
                        <div v-for="item in navbar_items" :key="item.id" class="">
                            <a :href="item.url" class="hover:opacity-75 duration-300">{{ item.title }}</a>
                        </div>
                        <!-- <div class="">
                            <p class="px-7 py-2 bg-x-amber text-white font-[500] rounded-t-full rounded-br-full cursor-pointer hover:opacity-70 duration-300" @click="showContactUsModal()">
                                {{ navbar_button.title }}
                            </p>
                        </div> -->
                        <a @click="showContactUsModal()" class="cursor-pointer bg-x-blue border-2 border-x-amber text-white active:bg-x-yellow font-bold uppercase px-5 py-2 rounded-full outline-none focus:outline-none ease-linear transition-all duration-150 hover:opacity-80">
                            Contact Us
                        </a>
                    </div>
                </div>
            </div>
            <div class="survey-container z-20 relative 2xl:px-56 lg:px-48 px-8 pb-8 lg:py-0 mt-20 md:mt-0 md:mb-0 mb-8">
                <div class="bg-x-blue_ w-full rounded-2xl backdrop-blur-sm bg-opacity-60">
                    <div class="survey-header-container text-2xl md:text-4xl">
                        <p class="survey-header">Solution Guide</p>
                    </div>
                    <Survey v-if="survey" :survey="survey" class="mb-12" />
                </div>
            <!-- <button
                v-if="survey?.isCompleted"
                @click="completeSurvey"
                class="mt-4 px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
                Submit
            </button> -->
            </div>

            <div class="h-8 flex items-center justify-center w-full fixed z-20 bottom-0 left-0 text-sm text-center p-2 text-white bg-x-amber 2xl:px-56 lg:px-48 md:px-8">
                <div class="flex justify-center md:justify-between w-full">
                    <p class="hidden md:block">OptimalX Smart Business (Pty) Ltd | Copyright 2024</p>
                    <div class="flex md:gap-5 w-full md:w-auto justify-evenly">
                        <a @click="showTermsModal()" class="cursor-pointer hover:opacity-75 duration-300">Terms & Conditions</a>
                        <a @click="showPrivacyModal()" class="cursor-pointer hover:opacity-75 duration-300">Privacy Policy</a>
                    </div>
                </div>
            </div>
            <div id="_test" ref="background_ref" class="fixed inset-0 z-0 w-screen h-screen bg-x-blue blur-sm bottom-0 left-0 flex items-end">
                <div class="w-full">
                    <img :src="images.bg" alt="Background" class="w-full object-cover opacity-30 hidden md:block" />
                    <img :src="images.bgMobile" alt="Background" class="w-full object-cover opacity-30 block md:hidden" />
                </div>
            </div>
        </div>
    </template>  

    <script>
    import { SurveyModel } from 'survey-core';
    import { Survey } from 'survey-vue';
    import { ref } from 'vue';
    import TermsPopup from './TermsPopup.vue';
    import PrivacyPopup from './PrivacyPopup.vue';

    const navbar_items = ref([
        { id: 0, title: 'Services', url: 'https://optimalx.co.za/#services' },
        // { id: 1, title: 'Discover More', url: '#chat' },
        // { id: 2, title: 'About', url: '#about' },
    ]);

    const images = {
        image1: new URL('../assets/1.1.png', import.meta.url).href,
        image2: new URL('../assets/1.2.png', import.meta.url).href,
        image3: new URL('../assets/1.3.png', import.meta.url).href,
        image4: new URL('../assets/1.4.png', import.meta.url).href,
        logo: new URL('../assets/x.png', import.meta.url).href,
        bg: new URL('../assets/bg-01.svg', import.meta.url).href,
        bgMobile: new URL('../assets/bg-mobile-01.svg', import.meta.url).href,
        servicesBg: new URL('../assets/services.bg.png', import.meta.url).href,
        whatsapp: new URL('../assets/socialmedia/whatsapp_icon.svg', import.meta.url).href,
        facebook: new URL('../assets/socialmedia/facebook_icon.svg', import.meta.url).href,
        linkedin: new URL('../assets/socialmedia/linkedin_icon.svg', import.meta.url).href,
        instagram: new URL('../assets/socialmedia/instagram_icon.svg', import.meta.url).href,
        x: new URL('../assets/socialmedia/x_icon.svg', import.meta.url).href,
        google: new URL('../assets/socialmedia/google_icon.svg', import.meta.url).href,
        insight: new URL('../assets/insight.png', import.meta.url).href,
        analytics: new URL('../assets/analytics.png', import.meta.url).href,
        workflow: new URL('../assets/workflow.png', import.meta.url).href,
        promotion: new URL('../assets/promotion.png', import.meta.url).href,
        xOutline: new URL('../assets/x_outline.svg', import.meta.url).href,
    };

    const showContactUs = ref(false);
    const submitted = ref(false);
    const msg = ref(null);
    const showTerms = ref(false);
    const showPrivacy = ref(false);
    const termsModal = ref(null);
    const privacyModal = ref(null);

    import { library } from '@fortawesome/fontawesome-svg-core'

    import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
    import { faPhone } from '@fortawesome/free-solid-svg-icons'
    import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
    import { faClose } from '@fortawesome/free-solid-svg-icons'
    import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
    import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
    import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
    import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

    library.add(faChevronDown)
    library.add(faPhone)
    library.add(faEnvelope)
    library.add(faClose)
    library.add(faArrowRight)
    library.add(faCheckCircle)
    library.add(faArrowUp)
    library.add(faArrowDown)

    export default {
        components: {
            Survey,
            TermsPopup,
            PrivacyPopup
        },
        setup() {
            return {
                navbar_items,
                images,
                showContactUs,
                submitted,
                msg,
                showTerms,
                showPrivacy,
                termsModal,
                privacyModal
            };
        },
        data() {
            return {
                survey: null,
                detailTexts: {
                    "noIdea": "No worries, you're in the right place. Let's uncover what your business needs.",
                    "someIdea": "Great! You've got a starting point. Let's refine your ideas and present you with the best options.",
                    "goodIdea": "Excellent! Let’s dive into specifics and tailor our solutions to fit your precise needs.",
                    "BusinessGrowth": "Explore strategic initiatives to drive business growth and market share expansion.",
                    "DataDecision": "Detailed planning on how to achieve your business growth targets over the next fiscal periods.",
                    "OperationEff": "Incorporate cutting-edge technologies and innovative processes to overhaul your business operations.",
                    "BrandMarketing": "Analysing market trends to better position your products and adapt to changing consumer preferences.",
                    "BusinessAssess": "Feeling lost about where your business is headed? Our <b>Business Assessment Studies</b> offers a deep dive to uncover hidden potential and pinpoint areas for growth. We provide customisable options, ranging from a basic analysis to a comprehensive review, all designed to deliver actionable insights and a personalised roadmap to boost your efficiency and refine your strategic direction.",
                    "MarketResearch": "Understanding the market is crucial for strategic planning. Our <b>Market Research Studies</b> range from basic market overviews to comprehensive market insights, offering detailed analyses of market size, trends, consumer behavior, and competitive landscapes. Tailored insights help you make informed decisions to navigate and excel in your industry.",
                    "BusinessDev": "For businesses aiming at growth and innovation, our <b>Business Development Studies</b> focus on identifying opportunities for market expansion, product innovation, and improving customer engagement. From strategic insights for small businesses to comprehensive market expansion mastery for established enterprises, we offer targeted analyses and actionable strategies to propel your business forward.",
                    "DataCollection": "Streamline your <b>Data Collection</b> to enhance decision-making with our methods tailored for varying needs. Basic Data Collection uses online surveys for rapid data gathering. Standard Data Collection includes observations and company records analysis for deeper insights. Advanced Data Collection integrates extensive social media analysis and web scraping to provide comprehensive market and operational intelligence.",
                    "DataAnalysis": "Enhance your strategic insights with our <b>Data Analysis</b> services, designed to scale with your needs. Start with Basic Analysis for trend identification and simple statistics. Progress to Moderate Analysis for deeper predictive insights, and achieve comprehensive strategic clarity with Advanced Analysis, which includes detailed predictive modeling and operational optimisation techniques.",
                    "DataReporting": "Transform complex data into clear, actionable insights with our <b>Data Reporting</b> services. Start with Interactive Dashboards for dynamic visualisation and user interaction, allowing for immediate insight exploration. Analysis Reports provide both static and dynamic options for routine or in-depth analysis. For engaging communication, Data Infographics offer visually appealing information presentation in both static and dynamic formats.",
                    "WorkflowOpt": "Improve your operational efficiency with our <b>Workflow Optimisation</b> services. We will kick off the process with Workflow Design to receive a customised, optimised workflow blueprint along with comprehensive process documentation. Then advance to Workflow Implementation for full system setup, tailored training materials, and initial support documentation. Maintain peak performance with Workflow Maintenance, including regular performance reports, update logs, and detailed troubleshooting guides.",
                    "WorkflowSol": "Leverage cutting-edge technologies with our <b>Workflow Solutions</b>. Custom Tool development offers tailored software tools and comprehensive support documentation. Custom Template development delivers professionally designed templates that enhance operational efficiency and branding. Custom Utility development creates specific utilities for task automation and efficiency. Workflow Technologies Implementation ensures seamless integration and high adoption of these technologies.", 
                    "BrandDevelop": "Elevate your brand with our tailored <b>Brand Development</b> packages. Starter Branding provides essential brand elements perfect for startups. Professional Branding enhances your existing identity with advanced content for a more dynamic presence. Premium Branding offers a comprehensive suite including custom assets and strategic consultation for top-tier corporate rebranding.",
                    "DigitalMarket": "Transform your online presence with our <b>Digital Marketing</b> packages. Starter Package sets up foundational elements like basic content strategy and initial analytics. Growth Package expands your reach with comprehensive strategies, full content creation, and optimisation of all marketing channels. Premium Package offers an all-encompassing solution with advanced strategies, including SEO, PPC, and detailed campaign execution plans for enterprises aiming to dominate the market.",     
                    "SmartInsight": "Our journey begins with <b>Smart Insight</b>, where thorough research is at the heart of good decision-making. By employing advanced methods to collect and analyse business and market data, we unveil insights that identify inefficiencies and opportunities within your business or in the competitive landscape it operates.",     
                    "SmartAnalytics": "Data is at the core of business decisions and our <b>Smart Analytics</b> transforms your business data into actionable insights. We use advanced methods to collect, analyse, and report your business or market data through easy-to-understand dashboards, reports, or presentations. This solution equips you to act quickly and effectively with confidence.",     
                    "SmartWorkflow": "With insights in hand, we enhance your workflow with <b>Smart Workflow</b> optimisation and solutions. Whether through crafting and executing a tailored workflow process, developing bespoke tools, templates, and utilities, or deploying proven technologies, we aim to boost workflow efficiency and productivity.",     
                    "SmartPromotion": "Market visibility is crucial for your business, and our <b>Smart Promotion</b> service is dedicated to crafting a unique brand identity that sets you apart. By using the latest marketing tools, we craft campaigns that ensure your brand not only garners attention but also leaves a lasting impression."     
                }
            };
        },
        mounted() {
            // Initialize the survey
            this.initSurvey();

            // Prepare to collect data when the survey is completed
            this.survey.onComplete.add((sender) => {
                // Collect survey data, questions, and answers
                const surveyData = sender.data;
                const questions = sender.getAllQuestions().map(question => ({
                    name: question.name,
                    title: question.title,
                    type: question.getType()
                }));

                // Match each question with its corresponding answer
                const matchedData = questions.map(question => ({
                    question: question.title,
                    answer: surveyData[question.name]
                }));

                // Log the matched data
                // console.log(matchedData);

                // array to JSON
                const surveyJSON = JSON.stringify(matchedData);

                // console.log(surveyJSON);

                // make an axios call to send the survey data to the backend
                fetch('https://uhjsfknnhjxtbwcwmmpl.supabase.co/functions/v1/survey', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${process.env.VUE_APP_SUPABASE_KEY}`,
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json'
                    },
                    body: surveyJSON
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok ' + response.statusText);
                    }
                    return response.json();
                })
                .catch(error => {
                    console.error('There was a problem with the fetch operation:', error);
                })
            });
        },
        methods: {
            initSurvey() {
                const json = {
                    // title: "OptimalX Solution Guide",
                    showNavigationButtons: true,
                    showQuestionNumbers: "off",
                    pages: [
                        this.startPage(),
                        this.someIdeaPage(),
                        this.insightPage(),
                        this.analyticsPage(),
                        this.workflowPage(),
                        this.promotionPage(),
                        this.finalSelectionPage("Select one or more options to tailor your business strategies effectively."),
                        this.createEmailPage()
                    ],
                    triggers: [
                        { type: "runexpression", expression: "{start} = 'noIdea'", runExpression: "survey.currentPageNo = 1" },
                        { type: "runexpression", expression: "{start} = 'someIdea' and {genBusiness} = 'BusinesGrowth'", runExpression: "survey.currentPageNo = 1" },
                        { type: "runexpression", expression: "{start} = 'someIdea' and {genBusiness} = 'DataDecision'", runExpression: "survey.currentPageNo = 2" },
                        { type: "runexpression", expression: "{start} = 'someIdea' and {genBusiness} = 'OperationEff'", runExpression: "survey.currentPageNo = 3" },
                        { type: "runexpression", expression: "{start} = 'someIdea' and {genBusiness} = 'BrandMarketing'", runExpression: "survey.currentPageNo = 4" },
                        { type: "runexpression", expression: "{start} = 'goodIdea'", runExpression: "survey.currentPageNo = 5" },
                    ]
                };

                this.survey = new SurveyModel(json);
                this.survey = new SurveyModel(json);
                this.survey.requiredText = "";
                this.survey.onValueChanged.add((sender, options) => {
                    const currentPanel = `rightPanel_${sender.currentPage.name}`;
                    const infoPanel = sender.getPanelByName(currentPanel).elements[0];
                    infoPanel.html = this.detailTexts[options.value] || this.detailTexts[options.name] || "Please make a selection see detailed information here.";
                });
            },
            startPage() {
                return {
                    name: "page1",
                    elements: [
                        {
                            type: "panel",
                            name: "leftPanel_page1",
                            width: "70%",
                            elements: [
                                {
                                    type: "radiogroup",
                                    name: "start",
                                    title: "What best describes your understanding of your business needs?",
                                    choices: [
                                        { value: "noIdea", text: "Uncertain, I need guidance." },
                                        { value: "someIdea", text: "Somewhat clear, but seeking options." },
                                        { value: "goodIdea", text: "Very clear, please provide specifics." }
                                    ],
                                    isRequired: true
                                }
                            ]
                        },
                        {
                            type: "panel",
                            name: "rightPanel_page1",
                            title: "More Information",
                            startWithNewLine: false,
                            width: "30%",
                            elements: [
                                {
                                    type: "html",
                                    name: "infoPanel_page1",
                                    html: "Please make a selection see detailed information here."
                                }
                            ]
                        }
                    ]
                };
            },
            someIdeaPage() {
                return {
                    name: "page2",
                    visibleIf: "{start} = 'someIdea'",
                    elements: [
                        {
                            type: "panel",
                            name: "leftPanel_page2",
                            width: "70%",
                            elements: [
                                {
                                    type: "radiogroup",
                                    name: "genBusiness",
                                    title: "What best describes your understanding of your business needs?",
                                    choices: [
                                        { value: "BusinessGrowth", text: "General Business Growth and Strategy" },
                                        { value: "DataDecision", text: "Data-Driven Decision Making" },
                                        { value: "OperationEff", text: "Operational Efficiency" },
                                        { value: "BrandMarketing", text: "Brand & Digital Marketing" }
                                    ],
                                    isRequired: true
                                }
                            ]
                        },
                        {
                            type: "panel",
                            name: "rightPanel_page2",
                            title: "Let's discover more",
                            startWithNewLine: false,
                            width: "30%",
                            elements: [
                                {
                                    type: "html",
                                    name: "infoPanel_page2",
                                    html: "Please make a selection see detailed information here."
                                }
                            ]
                        }
                    ]
                };
            },
            insightPage() {
                return {
                    name: "page3",
                    visibleIf: "{start} = 'noIdea' or ({start} = 'someIdea' and {genBusiness} = 'BusinessGrowth')",
                    elements: [
                        {
                            type: "panel",
                            name: "leftPanel_page3",
                            width: "70%",
                            elements: [
                                {
                                    type: "rating",
                                    name: "BusinessAssess",
                                    title: "How confident are you in improving and positioning your business effectively?",
                                    isRequired: true,
                                    rateMax: 5,
                                    minRateDescription: "Not",
                                    maxRateDescription: "Very"
                                },
                                {
                                    type: "rating",
                                    name: "MarketResearch",
                                    title: "How well do you know current market trends and consumer behaviors in your industry?",
                                    isRequired: true,
                                    rateMax: 5,
                                    minRateDescription: "Not",
                                    maxRateDescription: "Very"
                                },
                                {
                                    type: "rating",
                                    name: "BusinessDev",
                                    title: "How ready is your business to grow in new markets or with new products?",
                                    isRequired: true,
                                    rateMax: 5,
                                    minRateDescription: "Not",
                                    maxRateDescription: "Very"
                                }
                            ]
                        },
                        {
                            type: "panel",
                            name: "rightPanel_page3",
                            title: "Why is this important?",
                            startWithNewLine: false,
                            width: "30%",
                            elements: [
                                {
                                    type: "html",
                                    name: "infoPanel_page3",
                                    html: "Please select a rating to see detailed information here."
                                }
                            ]
                        }
                    ]
                };
            },
            analyticsPage() {
                return {
                    name: "page4",
                    visibleIf: "{start} = 'noIdea' or ({start} = 'someIdea' and {genBusiness} = 'DataDecision')",
                    elements: [
                        {
                            type: "panel",
                            name: "leftPanel_page4",
                            width: "70%",
                            elements: [
                                {
                                    type: "rating",
                                    name: "DataCollection",
                                    title: "How well-equipped is your business to gather important business and/or market data?",
                                    isRequired: true,
                                    rateMax: 5,
                                    minRateDescription: "Not",
                                    maxRateDescription: "Very"
                                },
                                {
                                    type: "rating",
                                    name: "DataAnalysis",
                                    title: "How confident are you in your ability to analyse your data effectively?",
                                    isRequired: true,
                                    rateMax: 5,
                                    minRateDescription: "Not",
                                    maxRateDescription: "Very"
                                },
                                {
                                    type: "rating",
                                    name: "DataReporting",
                                    title: "How effectively do you translate your data into meaningful insights?",
                                    isRequired: true,
                                    rateMax: 5,
                                    minRateDescription: "Not",
                                    maxRateDescription: "Very"
                                }
                            ]
                        },
                        {
                            type: "panel",
                            name: "rightPanel_page4",
                            title: "Why is this important?",
                            startWithNewLine: false,
                            width: "30%",
                            elements: [
                                {
                                    type: "html",
                                    name: "infoPanel_page4",
                                    html: "Please select a rating to see detailed information here."
                                }
                            ]
                        }
                    ]
                };
            },
            workflowPage() {
                return {
                    name: "page5",
                    visibleIf: "{start} = 'noIdea' or ({start} = 'someIdea' and {genBusiness} = 'OperationEff')",
                    elements: [
                        {
                            type: "panel",
                            name: "leftPanel_page5",
                            width: "70%",
                            elements: [
                                {
                                    type: "rating",
                                    name: "WorkflowOpt",
                                    title: "How well do your workflows achieve your business's efficiency and productivity goals?",
                                    isRequired: true,
                                    rateMax: 5,
                                    minRateDescription: "Not",
                                    maxRateDescription: "Very",
                                },
                                {
                                    type: "rating",
                                    name: "WorkflowSol",
                                    title: "How well do your business tools and processes support your workflow?",
                                    isRequired: true,
                                    rateMax: 5,
                                    minRateDescription: "Not",
                                    maxRateDescription: "Very",
                                }
                            ]
                        },
                        {
                            type: "panel",
                            name: "rightPanel_page5",
                            title: "Why is this important?",
                            startWithNewLine: false,
                            width: "30%",
                            elements: [
                                {
                                    type: "html",
                                    name: "infoPanel_page5",
                                    html: "Please select a rating to see detailed information here."
                                }
                            ]
                        }
                    ]
                };
            },
            promotionPage() {
                return {
                    name: "page6",
                    visibleIf: "{start} = 'noIdea' or ({start} = 'someIdea' and {genBusiness} = 'BrandMarketing')",
                    elements: [
                        {
                            type: "panel",
                            name: "leftPanel_page6",
                            width: "70%",
                            elements: [
                                {
                                    type: "rating",
                                    name: "BrandDevelop",
                                    title: "How well do you think your brand identity represents your business in the market?",
                                    isRequired: true,
                                    rateMax: 5,
                                    minRateDescription: "Not",
                                    maxRateDescription: "Very",
                                },
                                {
                                    type: "rating",
                                    name: "DigitalMarket",
                                    title: "How well do your digital marketing strategies work for your business?",
                                    isRequired: true,
                                    rateMax: 5,
                                    minRateDescription: "Not",
                                    maxRateDescription: "Very",
                                }
                            ]
                        },
                        {
                            type: "panel",
                            name: "rightPanel_page6",
                            title: "Why is this important?",
                            startWithNewLine: false,
                            width: "30%",
                            elements: [
                                {
                                    type: "html",
                                    name: "infoPanel_page6",
                                    html: "Please select a rating to see detailed information here."
                                }
                            ]
                        }
                    ]
                };
            },
            finalSelectionPage(defaultText) {
                return {
                    name: "finalPage",
                    elements: [
                        {
                            type: "panel",
                            name: "leftPanel_finalPage",
                            width: "70%",
                            elements: [
                                {
                                    type: "checkbox",
                                    name: "SmartInsight",
                                    title: "Want business and market Insight?",
                                    choices: [
                                        { value: "Business Assessment Study", text: "Business Assessment Study" },
                                        { value: "Market Research Study", text: "Market Research Study" },
                                        { value: "Business Development Study", text: "Business Development Study" }
                                    ],
                                    isRequired: false
                                },
                                {
                                    type: "checkbox",
                                    name: "SmartAnalytics",
                                    title: "Looking for a business Analytics solution?",
                                    choices: [
                                        { value: "Data Collection", text: "Data Collection" },
                                        { value: "Data Analysis", text: "Data Analysis" },
                                        { value: "Data Reporting", text: "Data Reporting" }
                                    ],
                                    isRequired: false
                                },
                                {
                                    type: "checkbox",
                                    name: "SmartWorkflow",
                                    title: "Streamline your business Workflow?",
                                    choices: [
                                        { value: "Workflow Optimisation", text: "Workflow Optimisation" },
                                        { value: "Workflow Solutions", text: "Workflow Solutions" }
                                    ],
                                    isRequired: false
                                },
                                {
                                    type: "checkbox",
                                    name: "SmartPromotion",
                                    title: "Next-level business Promotion?",
                                    choices: [
                                        { value: "Brand Development", text: "Brand Development" },
                                        { value: "Digital Marketing", text: "Digital Marketing" }
                                    ],
                                    isRequired: false
                                }
                            ]
                        },
                        {
                            type: "panel",
                            name: "rightPanel_finalPage",
                            title: "More Information",
                            startWithNewLine: false,
                            width: "30%",
                            elements: [
                                {
                                    type: "html",
                                    name: "infoPanel_finalPage",
                                    html: defaultText
                                }
                            ]
                        }
                    ]
                };
            },
            createEmailPage() {
                return {
                    name: "page6",
                    elements: [
                        {
                            type: "panel",
                            name: "leftPanel_page6",
                            width: "50%",
                            elements: [
                                {
                                    type: "text",
                                    name: "email",
                                    title: "Please enter your email address:",
                                    isRequired: true,
                                    validators: [
                                        {
                                            type: "email"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            type: "panel",
                            name: "rightPanel_page6",
                            title: "Thank you for completing the survey!",
                            startWithNewLine: false,
                            width: "50%",
                            elements: [
                                {
                                    type: "html",
                                    name: "infoPanel_page6",
                                    html: "We will send you a detailed report based on your responses. Please enter your email address to receive the report."
                                }
                            ]
                        }
                    ]
                };
            },
            completeSurvey() {
                this.survey.completeLastPage();
            },

            showContactUsModal(messageContents = '') {
                if (showContactUs.value == true) {
                    showContactUs.value = false;
                    this.$refs.contactUsModal.classList.remove('show');
                    this.$refs.contactUsModal.classList.add('hide');
                    this.message = '';
                }
                else if (showContactUs.value == false) {
                    showContactUs.value = true;
                    this.$refs.contactUsModal.classList.remove('hide');
                    this.$refs.contactUsModal.classList.add('show');
                    // remove all tabs at start of message
                    let msgResult = messageContents.replace(/\t/g, '');

                    this.message = msgResult;
                }
            },
            handleSubmit(event) {
                // Send form data to the server https://uhjsfknnhjxtbwcwmmpl.supabase.co/functions/v1/resend
                event.preventDefault(); 
                const form = document.querySelector('form');
                const formData = new FormData(form);

                // convert formData to JSON
                const object = {};
                formData.forEach((value, key) => {
                    object[key] = value;
                });

                const json = JSON.stringify(object);

                console.log('json:', json);

                // set submitted to true
                submitted.value = true;

                // send data to server
                fetch('https://uhjsfknnhjxtbwcwmmpl.supabase.co/functions/v1/resend', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${import.meta.env.VITE_SUPABASE_KEY}`,
                    },
                    body: formData
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok ' + response.statusText);
                    }
                    return response.json();
                })
                .then(() => {
                    if (msg.value) {
                        msg.value.classList.remove('hidden');
                        setTimeout(() => {
                            msg.value.classList.add('hidden');
                        }, 3000);
                    }
                    form.reset();
                })
                .catch(error => {
                    console.error('There was a problem with the fetch operation:', error);
                });
            },
            showTermsModal() {
                if (showTerms.value == true) {
                    showTerms.value = false;
                    this.$refs.termsModal.classList.remove('show');
                    this.$refs.termsModal.classList.add('hide');
                }
                else if (showTerms.value == false) {
                    showTerms.value = true;
                    this.$refs.termsModal.classList.remove('hide');
                    this.$refs.termsModal.classList.add('show');
                }
            },
            showPrivacyModal() {
                if (showPrivacy.value == true) {
                    console.log('showPrivacy:', showPrivacy.value);
                    showPrivacy.value = false;
                    this.$refs.privacyModal.classList.remove('show');
                    this.$refs.privacyModal.classList.add('hide');
                }
                else if (showPrivacy.value == false) {
                    console.log('showPrivacy:', showPrivacy.value);
                    showPrivacy.value = true;
                    this.$refs.privacyModal.classList.remove('hide');
                    this.$refs.privacyModal.classList.add('show');
                }
            },
        }
    };
    </script>

    <style scoped>
    .survey-container {
        /* background-color: red; */
        /* margin: 0; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh;
    }

    @media (max-width: 768px) {
        .survey-container {
            height: 100%;
            justify-content: start;
        }
    }

    .survey-header-container {
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: center;
    }

    .survey-header {
        /* font-size: 1.5rem; */
        color: white;
        margin: 0;
        padding: 1rem 0;
        /* font-size: 2.25rem; */
        /* line-height: 2.5rem; */
        font-family: 'Raleway', sans-serif;
    }

    @media (max-width: 768px) {
        .survey-header {
            /* font-size: 2rem; */
            /* line-height: 1.75rem; */
            padding-left: 0;
            padding-bottom: 0;
        }
    }

    .w-32 {
    width: 32px;
    }

    .show {
        animation: showHide 0.25s ease-in-out 0.05s forwards;
        display: flex;
    }

    .hide {
        display: none;
    }

    @keyframes showHide {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    </style>
