<template>
    <div class="Home">
      <h1>Welcome to the Home Page</h1>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HomePage'
  }
  </script>
  