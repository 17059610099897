import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// import './assets/styles.css';
import './assets/survey-style.css';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// firebase analytics
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyDVHf6du-0b0daJCyYbF4gcnu7iu0HB02o",
    authDomain: "optimalx-solution-guide.firebaseapp.com",
    projectId: "optimalx-solution-guide",
    storageBucket: "optimalx-solution-guide.appspot.com",
    messagingSenderId: "703387897780",
    appId: "1:703387897780:web:627bda8b204a37c115ece1",
    measurementId: "G-0EQMRFQZFZ"
};

Vue.config.productionTip = false;

Vue.component('font-awesome-icon', FontAwesomeIcon);

const app = initializeApp(firebaseConfig);

/* eslint-disable-next-line no-unused-vars */
const analytics = getAnalytics(app);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');