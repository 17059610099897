<template>
  <div id="app">
    <survey-component/>
  </div>
</template>

<script>
import SurveyComponent from './components/SurveyComponent';

export default {
  name: 'App',
  components: {
    SurveyComponent
  }
};
</script>
